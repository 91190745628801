import React, { useRef, useLayoutEffect, useEffect, useState, useContext } from 'react'
import * as d3 from 'd3'

import Chart from './LollipopChart'
import { margins } from '../../constants'
import { store } from '../../store'
import { ChartControl } from '../../components'
import { isMobileDevice } from '../../utils/isMobile'

const Canvas = ({ width, height }) => {
  const { year, data: { data: {regressionData} } } = useContext(store)
  const chartRef = useRef()
  const [scales, setScales] = useState()
  const [dimensions, setDimensions] = useState({ width, height: height * 0.7 })

  useLayoutEffect(() => {
    if (chartRef.current) {
      setDimensions(dimensions => { 
        return {
          width,
          height: dimensions.height
        }
      })
    }
  }, [width])

  useEffect(() => {
    const canvas = d3.select('#lollipop__canvas')
    if (dimensions.width && dimensions.height && regressionData && year) {
      if (!scales) {
        const xScale = d3.scaleLinear().domain([0, 1]).range([margins.left + 100, dimensions.width - margins.right - 100])

        let sortedCountries = regressionData.filter(datum => datum.year === year)[0]?.countries.sort((a, b) => b.epi - a.epi).map(countryObject => countryObject.country)
        const yScale = d3.scaleBand().domain(sortedCountries).range([margins.top, dimensions.height - margins.bottom]).padding(1)

        canvas.append('g')
        .attr('id', 'x-axis')
        .attr('transform', `translate(0, ${dimensions.height - margins.bottom})`)
        .call(d3.axisBottom(xScale))
        
        canvas.append('g')
        .attr('id', 'y-axis')
        .attr('class', 'noselect')
        .attr('transform', `translate(${margins.left + 100}, 0)`)
        .call(d3.axisLeft(yScale))
        
        canvas.select('#y-axis')
        .selectAll('text')
        .style('font-size', '1rem')

        canvas.select('#y-axis')
        .selectAll('line')
        .remove()

        setScales({
          xScale,
          yScale
        })
      } else {
        let sortedCountries = regressionData.filter(datum => datum.year === year)[0]?.countries.sort((a, b) => b.epi - a.epi).map(countryObject => countryObject.country)
        const yScale = d3.scaleBand().domain(sortedCountries).range([margins.top, dimensions.height - margins.bottom]).padding(1)
        const xScale = d3.scaleLinear().domain([0, 1]).range([margins.left + 100, dimensions.width - margins.right - 100])

        setScales({ xScale, yScale })

        d3.select('#lollipop__canvas').select('#x-axis')
        .transition()
        .duration(750)
        .call(d3.axisBottom(xScale).ticks(11).tickFormat((x, i) => { 
          if (i === 0) return 0
          if (i === 10) return 1
          return x.toString().substr(1)
        }))

        d3.select('#lollipop__canvas').select('#y-axis')
        .transition()
        .duration(750)
        .call(d3.axisLeft(yScale))
      }
      }
  }, [year, regressionData, dimensions]) // eslint-disable-line


  const controlPosition = isMobileDevice ? { right: '30px', bottom: '30px' } : { right: '130px', bottom: '30px' }
  return (
    <div className={'lollipop'} style={dimensions.width ? { width: `${dimensions.width}px`} : {}}>
      <div
        id="lollipop"
        ref={chartRef}
        className="canvas"
        style={dimensions.height ? { height: `${dimensions.height}px`, width: '100%' } : {}}
        >
        {/* basic responsiveness pattern for svg's => you can force dimensions consistency by setting it to a specific px value (from props or local state) or allow it to vary by setting it to 100% */}
        <svg
          id="lollipop__canvas"
          viewBox={`0 0 ${dimensions.width} ${dimensions.height}`}
          height='100%'
          width='100%'
        >
          <Chart
            data={regressionData && regressionData.filter(datum => datum.year === year)}
            width={dimensions.width}
            height={dimensions.height}
            scales={scales} />
        </svg>
        <ChartControl position={controlPosition} />
      </div>
      {/* Add anything that should be outside of the chart grid area */}
    </div>
  )
}

export default Canvas
