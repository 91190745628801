import React from 'react'

const QuestionMark = ({ size = 30, color }) => {
  return (
    <svg width={size} height={size} fill={color} version="1.1" viewBox={`0 0 ${size} ${size}`} xmlns="http://www.w3.org/2000/svg">
      <g transform="matrix(.071946 0 0 .071946 -6.1283e-6 -2.4115e-5)">
        <path d="m356 61.156c-81.37-81.47-213.38-81.551-294.85-0.182-81.47 81.371-81.552 213.38-0.181 294.85 81.369 81.47 213.38 81.551 294.85 0.181 81.469-81.369 81.551-213.38 0.18-294.85zm-147.45 273.64c-11.028 0-19.968-8.939-19.968-19.968s8.939-19.969 19.968-19.969c11.028 0 19.968 8.939 19.968 19.969-1e-3 11.028-8.94 19.968-19.968 19.968zm32.464-120.23c-11.406 6.668-12.381 14.871-12.43 38.508-3e-3 1.563-8e-3 3.14-0.017 4.726-0.071 11.172-9.147 20.18-20.304 20.18h-0.131c-11.215-0.071-20.248-9.22-20.178-20.436 0.01-1.528 0.013-3.047 0.016-4.552 0.05-24.293 0.111-54.524 32.547-73.484 26.026-15.214 29.306-25.208 26.254-38.322-3.586-15.404-17.653-19.396-28.63-18.141-3.686 0.423-22.069 3.456-22.069 21.642 0 11.213-9.092 20.306-20.307 20.306s-20.306-9.093-20.306-20.306c0-32.574 23.87-58.065 58.048-61.989 35.2-4.038 65.125 16.226 72.816 49.282 11.497 49.381-29.772 73.505-45.309 82.586z"/>
      </g>
    </svg>
  )
}

export default QuestionMark
