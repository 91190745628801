import React from 'react'

const ScrollToTop = ({ size = 30, color }) => {
  return (
    <svg width={size} height={size} fill={color} version="1.1" viewBox={`0 0 ${size} ${size}`} xmlns="http://www.w3.org/2000/svg">
      <g transform="matrix(.061079 0 0 .061079 0 -.032677)">
        <path d="m123.8 100.44c-7.3-8.3-22.9-8.3-30.2 0l-87.4 96.8c-8.3 8.3-7.3 21.8 1 29.1 8.3 8.3 21.8 7.3 29.1-1l51-56.1v300.6c0 11.4 9.4 20.8 20.8 20.8s20.8-9.4 20.8-19.8v-301.4l51 56.9c15.8 11.7 25 4.2 30.2 0 8.3-8.3 8.3-20.8 1-29.1z"/>
        <path d="m485.9 197.14-87.4-96.8c-7.3-8.3-22.9-8.3-30.2 0l-87.4 96.8c-8.3 8.3-7.3 21.8 1 29.1 8.3 8.3 21.8 7.3 29.1-1l51-56.1v300.6c0 11.4 9.4 20.8 20.8 20.8s19.8-9.4 20.8-19.8v-301.3l51 56.9c13.8 11.3 25 4.2 30.2 0 8.3-8.4 8.3-20.9 1.1-29.2z"/>
        <path d="m20.8 42.135h449.4c10.4 0 19.8-9.4 19.8-20.8s-9.4-20.8-20.8-20.8h-448.4c-11.4 0-20.8 9.4-20.8 20.8 0 11.5 9.4 20.8 20.8 20.8z"/>
      </g>
    </svg>
  )
}

export default ScrollToTop
