import React from 'react'

import { useWindowDimensions } from '../store'
import { routes } from '../constants'

const Page = ({ id, children, index }) => {
  const { height } = useWindowDimensions()

  return (
    <section className='page' id={id} style={{ height }}>
      <div data-aos="fade-up" className="page__container">
        {children}
      </div>
      <div data-aos data-aos-anchor-placement='top-center' className="page__navigation noselect fade-out">
        {routes[index]?.guide}
     </div>
    </section>
  )
}

export default Page
