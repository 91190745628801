import * as d3 from 'd3'

import { margins, colors } from '../constants'

export const appendAxis = ({ type, dimensions, scale, selection, clean, color = colors.primary }) => {
  switch (type) {
    case 'xAxis':
      return selection.append('g')
        .attr('id', 'x-axis')
        .attr('transform', `translate(0, ${dimensions.height - margins.bottom})`)
        .call(d3.axisBottom(scale))
    case 'yAxis':
      return selection.append('g')
        .attr('id', 'y-axis')
        .attr('transform', `translate(${margins.left}, 0)`)
        .call(d3.axisLeft(scale))
        .call(g => {
          if (clean) {
            g.call(g => g.selectAll('.tick')
              .filter(function (d) { return d === 0 })
              .remove())
              .call(g => g.select('.domain').remove()) // removing the path'd axis
              .append('line') // adding a straight line as axis, without the corners
              .attr('class', 'y-axis-line')
              .attr('x1', 0)
              .attr('x2', 0)
              .attr('y1', margins.top)
              .attr('y2', dimensions.height - margins.bottom)
              .attr('stroke', color)
          }
        })
    default:
      return null
  }
}

export const updateAxis = ({ type, dimensions, scale, selection }) => {
  switch (type) {
    case 'xAxis':
      return selection.select('#x-axis')
        .attr('transform', `translate(0, ${dimensions.height - margins.bottom})`)
        .call(d3.axisBottom(scale))
    case 'yAxis':
      return selection.select('#y-axis')
        .attr('transform', `translate(${margins.left}, 0)`)
        .call(d3.axisLeft(scale))
    default:
      return null
  }
}
