import * as d3 from 'd3'

import europeMeanData from '../assets/data/epi_europe_mean'
import eurostatData from '../assets/data/epi_eurostat.csv'
import trendLineData from '../assets/data/regressions.csv'

const parse = () => {
  const yearArray = []
  const countryArray = []
  let [minEpi, maxEpi, minEmp, maxEmp, minIneq, maxIneq, minRD, maxRD, minDemoc, maxDemoc, minLabShare, maxLabShare, minPover, maxPover] = Array(14).fill(0)

  return Promise.all([
    d3.csv(eurostatData, (row, index) => {
      if (!yearArray.includes(+row.year)) yearArray.push(+row.year)
      if (!countryArray.includes(row.country)) countryArray.push(row.country)

      minEmp = index === 0 ? +row.emp : (+row.emp && minEmp < +row.emp) ? minEmp : +row.emp
      maxEmp = (+row.emp && maxEmp > +row.emp) ? maxEmp : +row.emp
      if (row.ineq !== '.') {
        minIneq = index === 0 ? +row.ineq : minIneq < +row.ineq ? minIneq : +row.ineq
        maxIneq = maxIneq > +row.ineq ? maxIneq : +row.ineq
      }
      if (row.RD !== '.') {
        minRD = index === 0 ? +row.RD : minRD < +row.RD ? minRD : +row.RD
        maxRD = maxRD > +row.RD ? maxRD : +row.RD
      }
      minDemoc = index === 0 ? +row.democ : minDemoc < +row.democ ? minDemoc : +row.democ
      maxDemoc = maxDemoc > +row.democ ? maxDemoc : +row.democ
      if (row.labshare !== '.') {
        minLabShare = index === 0 ? +row.labshare : minLabShare < +row.labshare ? minLabShare : +row.labshare
        maxLabShare = maxLabShare > +row.labshare ? maxLabShare : +row.labshare
      }
      if (row.pover !== '.') {
        minPover = index === 0 ? +row.pover : minPover < +row.pover ? minPover : +row.pover
        maxPover = maxPover > +row.pover ? maxPover : +row.pover
      }

      return {
        [row.country]: {
          [+row.year]: {
            employment: +row.emp || null,
            inequality: +row.ineq || null,
            rd: +row.RD || null,
            democracy: +row.democ || null,
            labourShare: +row.labshare || null,
            poverty: +row.pover || null,
            workplace: +row.workplace || null,
            cbc: +row.cbc || null,
            bler: +row.bler || null,
            density: +row.density || null,
            epi: +row.epi || null,
            country: row.country,
            year: +row.year,
            id: +row.epi + +row.labshare + +row.year
          }
        }
      }
    }),
    d3.csv(trendLineData, (row, index) => {
      return {
        year: +row.year,
        indicator: row.var,
        intercept: +row.intercept || null,
        slope: +row.slope || null
      }
    })
  ]).then((files) => {
    yearArray.sort((a, b) => a - b)

    let yearData = []

    const combinedCountryData = countryArray.map(country => {
      const countryData = { country, years: {} }

      yearArray.forEach(year => {
        const countryObject = files[0].filter(datum => !!datum[country]).filter(datum => !!datum[country][year]) // [ [country]: { [year]: { whateverdata } }]
        const fullCountryData = { [year]: { ...countryObject[0][country][year] } } // { [year]: { whateverdata} } }
        countryData.years = { ...countryData.years, ...fullCountryData }
        yearData = [...yearData, fullCountryData]
      })
      return countryData
    })

    const regressionData = yearArray.map(year => {
      let countries = []
      const data = yearData
        .flat() // from array of array of objects to array of objects
        .filter(datum => datum[year]) // make array of country data relating to same year

      countries = data.map(datum => Object.values(datum)[0]) // return the values of each { year: { ...countryData }}
      return { year, countries }
    })

    const extents = {
      epi: [minEpi, maxEpi],
      year: yearArray,
      employment: [minEmp, maxEmp],
      inequality: [minIneq, maxIneq],
      rd: [minRD, maxRD],
      democracy: [minDemoc, maxDemoc],
      labourShare: [minLabShare, maxLabShare],
      poverty: [minPover, maxPover]
    }

    return {
      data: {
        regressionData,
        countryData: combinedCountryData,
        europeMeanData: europeMeanData.filter(datum => yearArray.includes(datum.year)),
        trendlineData: files[1]
      },
      extents,
      countries: countryArray
    }
  })
}

export { parse }
