import React from 'react'
import * as Chart from '../charts'
import { colors } from './colors'

const routes = [
  {
    content: <h1>A better world with more <span className='emphasize'>democracy at work</span></h1>,
    hash: 'Title',
    guide: <span>Scroll down or press the &darr; key to navigate.</span>
  },
  {
    hash: 'EPI_vs_Indicators',
    guide: <span>Scroll down to find out what we mean by <span className='emphasize'>democracy at work</span>.</span>
  },
  {
    content: <div className="page__two">
      <p>Democracy at work refers to all institutions and organisations that in some way enhance workers’ voice and democratic oversight in their work, companies and the economy at large. For this cross-country analysis, we use the <span className="emphasize">European Participation Index</span> (EPI) to measure democracy at work.</p>
      <div className="page__two--epi">
        <h4 className="page__two--title">The European Participation Index</h4>
        <span className="page__two--text">
      The <span className="emphasize">EPI</span> measures the degree of democracy at work in the different European countries. The index, which has a range from 0 to 1 and is calculated at the country level, is a combination of three factors:
          <span className="emphasize">
            <ol>
              <li>collective bargaining coverage and trade union density</li>
              <li>presence of worker representatives at the establishment level, and</li>
              <li>employee representation in company boards</li>
            </ol>
          </span>
        </span>
        <h6 className="page__two--attribution">The <span className="emphasize">EPI</span> has been developed by Sigurt Vitols -- more information can be found in <a href="https://www.worker-participation.eu/content/download/4282/58932/file/EPI-background-paper.pdf" target="_blank" rel="noopener noreferrer">this background paper</a></h6>
      </div>
    </div>,
    hash: '2',
    guide: <span>We have seen that democracy at work is related to many positive outcomes. But how has democracy at work evolved in Europe? Scroll down to find out.</span>
  },
  {
    content: <Chart.Line/>,
    hash: 'EPI_EuropeMean',
    guide: <span>Which countries are doing best in terms of democracy at work? Find out below.</span>
  },
  {
    content: <Chart.Lollipop/>,
    hash: 'EPI_Countries',
    guide: <span>Still want to know more? Scroll down.</span>
  },
  {
    content: <div style={{ width: '100%' }}>
      <p>More information on the European Participation Index can be found in <a href="https://www.worker-participation.eu/content/download/4282/58932/file/EPI-background-paper.pdf" target="_blank" rel="noopener noreferrer">this background paper</a>.</p>
      <br />
      <p>Read the chapters on Democracy at work in Benchmarking Working Europe <a href="https://www.etui.org/publications/books/benchmarking-working-europe-2019" target="_blank" rel="noopener noreferrer">2019</a> and 2020.</p>
      <br />
      <p>Please cite this page as:</p>
      <br />
      <div style={{ backgroundColor: colors.secondaryLight, padding: '1rem', wordBreak: 'break-word' }}>
      De Spiegelaere S., Vitols, S., (2020) A better world with more democracy at work. europeanparticipationindex.eu, European Trade Union Institute (ETUI), http://www.europeanparticipationindex.eu/betterworldmoredemocracyatwork
      </div>
    </div>,
    hash: 'info'
  }
]

export { routes }
