import React, { useEffect, useRef, useContext } from 'react'
import * as d3 from 'd3'

import { store } from '../../store'
import { colors } from '../../constants'

const RegressionChart = ({ scales, scatterData, trendlineData, width, height, isGraphDisabled }) => {
  const chartRef = useRef(null)
  const { year, dialog, setDialog, highlightedCountry, setHighlightedCountry, selectedIndicator } = useContext(store)

  const getTrendLineValueY = (i, s, y) => i + (s * y)

  useEffect(() => {
    if (scatterData && scales && trendlineData) {
      const viz = d3.select(chartRef.current)
        .attr('height', height)
        .attr('width', width)

      viz.selectAll('circle').data(scatterData, d => d.country)
        .join(
          enter => enter.append('circle')
            .attr('id', d => d.country)
            .attr('opacity', 0)
            .attr('r', 0)
            .attr('cy', height / 2)
            .attr('cx', width / 2),
            update => update,
          exit => exit.transition().duration(750)
            .attr('r', 20)
            .attr('opacity', 0)
            .attr('fill', 'red')
            .remove()
        )
        .on('mouseover', (data, index, nodes) => {
          if (!isGraphDisabled) {
            setHighlightedCountry(data.country)
            d3.select(nodes[index]).style('cursor', 'pointer')
              
            d3.select('#scatter').append('div').html(`<p>${data.country}</p>`)
              .attr('class', 'tooltip')
              .attr('id', `${data.country}--tt`)
              .style("left", scales.xScale(data.epi) + "px")
              .style('top', scales.yScale(data[selectedIndicator]) - 50 + 'px')
              .attr('transform', 'translateX(-50%)')
              .style('opacity', 0)
              .transition()
                .duration(500)
                .style("opacity", .9) 
          }
        })
        .on('mouseout', (data, index, nodes) => {
          if (!isGraphDisabled) {
            setHighlightedCountry()
            d3.select(nodes[index])
              .style('cursor', 'default')
              
            d3.selectAll(`.tooltip`).transition().duration(200).style('opacity', 0).remove()
          }
        })
        .on('click', !isGraphDisabled && setDialog)
        .transition().duration(750)
        .attr('fill', colors.primaryLight)
        .attr('cy', d => scales.yScale(d[selectedIndicator]))
        .attr('cx', d => scales.xScale(d.epi))
        .attr('r', d => (highlightedCountry && d.country === highlightedCountry) || (dialog?.data.country === d.country) ? 15 : 10)
        .attr('opacity', d => (highlightedCountry && d.country === highlightedCountry) || (dialog?.data.country === d.country) ? 1 : 0.5)

      viz.selectAll('line').data(trendlineData.filter(datum => datum.year === year))
        .join(
          enter => enter.append('line')
          .attr('id', 'trendline')
          .attr('stroke', colors.primaryLight)
          .attr('stroke-width', 0)
          .attr('x1', scales.xScale(0.5))
          .attr('x2', scales.xScale(0.5))
          .attr('y1', scales.yScale(scales.yScale.domain()[0] / 2) )
          .attr('y2', scales.yScale(scales.yScale.domain()[0] / 2) ),
          update => update,
          exit => exit.remove(),
          ).transition().duration(750)
          .attr('stroke-width', d => d.intercept ? 3 : 0)
          .attr('opacity', d => d.intercept ? 1 : 0)
          .attr('x1', d => d.intercept ? scales.xScale(0) : scales.xScale(0.5))
          .attr('x2', d => d.intercept ? scales.xScale(1) : scales.xScale(0.5))
          .attr('y1', d => d.intercept ? scales.yScale(getTrendLineValueY(d.intercept, d.slope, 0)) : scales.yScale(scales.yScale.domain()[0] / 2))
          .attr('y2', d => d.intercept ? scales.yScale(getTrendLineValueY(d.intercept, d.slope, 1)) : scales.yScale(scales.yScale.domain()[0] / 2))
    }
  }, [scatterData, width, height, scales, year, highlightedCountry, dialog, setHighlightedCountry, setDialog, selectedIndicator, trendlineData, isGraphDisabled ])

  return (
    <g ref={chartRef} />
  )
}

export default RegressionChart
