import React, { useEffect, useLayoutEffect, useRef, useState, useContext } from 'react'
import * as d3 from 'd3'

import RegressionChart from './RegressionChart'
import { margins, indicators, colors } from '../../constants'
import { store } from '../../store'
import { chartUtils } from '../../utils'
import { ChartControl } from '../../components'
import { ArrowButton } from '../../assets/icons'

const Canvas = ({ width, height, isGraphDisabled, helper, setNextHelper, isDescriptionButtonDisabled, isLoopButtonDisabled, isYearButtonDisabled }) => {
  const chartRef = useRef()
  const { year, data, selectedIndicator } = useContext(store)

  const [scales, setScales] = useState()
  const [dimensions, setDimensions] = useState({ width, height: height * 0.65 })
  const [descriptionStyle, setDescriptionStyle] = useState({})
  const [isVisible, setIsVisible] = useState(false)

  useLayoutEffect(() => {
    if (chartRef.current) {
      setDimensions(dimensions => {
        return {
          width,
          height: dimensions.height
        }
      })
    }
  }, [width])

  useEffect(() => {
    let timer1
    if (isVisible) {
      setDescriptionStyle({
        height: dimensions.height - margins.top - margins.bottom - 35,
        width: dimensions.width - 10 - margins.right - margins.left - margins.left - 40,
        top: margins.top,
        left: margins.left + 10
      })
      if (helper === 'description') setNextHelper()
    } else {
      timer1 = setTimeout(() => setDescriptionStyle(), 700)
    }
    return () => clearTimeout(timer1)
  }, [isVisible, dimensions, helper, setNextHelper])

  useEffect(() => {
    /**
     * SET SCALES
     */
    const canvas = d3.select('#scatterplot__canvas')
    let yScale = scales ? scales.yScale : null
    if (dimensions.width && dimensions.height && data && selectedIndicator) {
      if (!scales) {
        yScale = d3.scaleLinear().domain([Math.ceil(data.extents[selectedIndicator][1]), Math.floor(data.extents[selectedIndicator][0])]).range([margins.top, dimensions.height - margins.bottom])
        const xScale = d3.scaleLinear().domain([0, 1]).range([margins.left, dimensions.width - margins.right - 40])

        chartUtils.appendAxis({
          selection: canvas,
          type: 'xAxis',
          dimensions,
          scale: xScale
        })

        chartUtils.appendAxis({
          selection: canvas,
          type: 'yAxis',
          dimensions,
          scale: yScale
        })

        return setScales({
          xScale,
          yScale
        })
      } else {
        yScale = d3.scaleLinear().domain([Math.ceil(data.extents[selectedIndicator][1]), Math.floor(data.extents[selectedIndicator][0])]).range([margins.top, dimensions.height - margins.bottom])
        const xScale = d3.scaleLinear().domain([0, 1]).range([margins.left, dimensions.width - margins.right - 40])

        chartUtils.updateAxis({
          selection: canvas,
          dimensions,
          scale: xScale,
          type: 'xAxis'
        })
        chartUtils.updateAxis({
          selection: canvas,
          dimensions,
          scale: yScale,
          type: 'yAxis'
        })

        setScales({ yScale, xScale })
      }
    }
  }, [dimensions, data, selectedIndicator]) // eslint-disable-line

  if (!selectedIndicator) return null
  const scatterData = data.data.regressionData && data.data.regressionData.filter(datum => datum.year === year)[0].countries.filter(country => country[selectedIndicator])
  const trendlineData = data.data.trendlineData && data.data.trendlineData.filter(datum => datum.indicator === selectedIndicator)
  return (
    <div className='scatterplot' style={ dimensions.width ? { width: `${dimensions.width}px` } : {}}>
      {selectedIndicator && <div className="scatterplot__xAxis--label"><p>{Object.values(indicators).filter(indicator => indicator.value === selectedIndicator)[0].subLabel}</p></div>}
      <div
        id="scatter"
        ref={chartRef}
        className="canvas"
        style={dimensions.height ? { height: `${dimensions.height}px` } : {}}
      >
        <svg
          id='scatterplot__canvas'
          viewBox={`0 0 ${dimensions.width} ${dimensions.height}`}
          height='100%'
          width='100%'
        >
          <RegressionChart
            scales={scales}
            scatterData={scatterData}
            trendlineData={trendlineData}
            width={dimensions.width}
            height={dimensions.height}
            isGraphDisabled={isGraphDisabled}
          />
        </svg>
        <ArrowButton
          direction={!isVisible ? 'left' : 'right'}
          color={colors.secondaryDarker}
          onClick={() => setIsVisible(!isVisible)}
          className='canvas__chevron'
          size={40}
          isDisabled={isDescriptionButtonDisabled}
        />
        <ChartControl position={{ left: '50px', bottom: '30px' }} isButtonDisabled={isLoopButtonDisabled} isYearButtonDisabled={isYearButtonDisabled} />
        <div
          className={`canvas__indicator ${!isVisible ? 'animate--ltr' : 'animate--rtl'}`}
          style={descriptionStyle}
        >
          <div className="canvas__indicator--description">
            {indicators[selectedIndicator].text}
            <h6 className="canvas__indicator--source">--- {indicators[selectedIndicator].source}</h6>
          </div>
        </div>
        {!scatterData.length && <div className="noData"><h1>no data</h1></div>}
      </div>
    </div>
  )
}

export default Canvas
