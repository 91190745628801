import React, { useEffect, useRef, useContext } from 'react'
import * as d3 from 'd3'
import { store } from '../../store'
import { colors } from '../../constants'

const Chart = (props) => {
  const chartRef = useRef(null)
  const { highlightedCountry, setHighlightedCountry, setDialog, dialog } = useContext(store)

  useEffect(() => {
    if (props.data && props.scales) {
      const viz = d3.select(chartRef.current)
        .attr('height', props.height)
        .attr('width', props.width)

      viz.selectAll('line')
        .data(props.data[0].countries, d => d.country)
        .join(
          enter => enter.append('line')
            .attr('x2', props.scales.xScale(0))
            .attr('x1', props.scales.xScale(0))
            .attr('stroke', colors.primaryLight)
            .attr('opacity', 0.65)
            .attr('y1', d => props.scales.yScale(d.country))
            .attr('y2', d => props.scales.yScale(d.country)),
          update => update
        ).transition().duration(750)
          .attr('x1', d => props.scales.xScale(d.epi))
          .attr('y1', d => props.scales.yScale(d.country))
          .attr('y2', d => props.scales.yScale(d.country))

      viz.selectAll('circle')
        .data(props.data[0].countries, d => d.country)
        .join(
          enter => enter.append('circle')
            .attr('id', d => `epiMean_${d.country}`)
            .attr('r', 0)
            .attr('cx', props.scales.xScale(0))
            .attr('cy', d => props.scales.yScale(d.country)),
          update => update
        )
        .on('mouseover', (data, index, nodes) => {
          setHighlightedCountry(data.country)
          d3.select(nodes[index])
            .style('cursor', 'pointer')
        })
        .on('mouseout', (data, index, nodes) => {
          setHighlightedCountry()
        })
        .on('click', setDialog)
        .transition()
        .duration(750)
        .attr('cy', d => props.scales.yScale(d.country))
        .attr('r', d => (highlightedCountry && d.country === highlightedCountry) || (dialog?.data.country === d.country) ? 10 : 5)
        .attr('cx', d => props.scales.xScale(d.epi))
        .attr('fill', colors.primaryLight)
    }
  }, [props.data, props.scales, props.width, props.height, highlightedCountry, dialog, setHighlightedCountry, setDialog])

  return (
    <g
      ref={chartRef}
    />
  )
}

export default Chart
