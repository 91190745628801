import React, { useEffect, useState, useContext, useRef } from 'react'
import AOS from 'aos'

import { store, useWindowDimensions } from './store'
import Page from './ui/Page'
import { Spinner } from './components'
import { routes, colors } from './constants'
import IndicatorSelectionPage from './pages/IndicatorSelectionPage'
import { EtuiLogo, Link, QuestionMark, ScrollToTop } from './assets/icons'
import { dataUtils } from './utils'
import { isMobileDevice } from './utils/isMobile'
import { useOnClickOutside } from './hooks'

import 'aos/dist/aos.css'

AOS.init({
  duration: 1000,
  delay: 150,
  disable: 'phone'
})

const controlsStyle = isMobileDevice ? { top: '25px', left: '25px', flexDirection: 'row-reverse' } : { top: 'calc(50% - 68px)', left: '25px', flexDirection: 'column' } // 68 = 45 + 45/2

function App () {
  const { isLoading, hashString, setHashString, setYear, setData, selectedIndicator, pageIndex, setPageIndex, shouldLoadAll } = useContext(store)
  const { height: windowHeight } = useWindowDimensions()
  const [showAttribution, setShowAttribution] = useState(false)
  const [copyButtonStyle, setCopyButtonStyle] = useState({ backgroundColor: 'transparent' })
  const popoutRef = useRef(null)
  const pageBreakArray = routes.map((route, index) => index * windowHeight)

  useOnClickOutside(popoutRef, () => setShowAttribution(false))

  // in order to access the latest state in eventhandlers, we need to use refs
  const pageIndexRef = useRef(pageIndex)

  const changePage = (index, withScrollSnap = false) => {
    setPageIndex(index)
    updateUrl(index, withScrollSnap)
  }

  const updateUrl = (index = null, withScrollSnap) => {
    const updatedIndex = index !== null ? index : pageIndex
    pageIndexRef.current = updatedIndex
    window.history.pushState({}, '', (updatedIndex === 1 && selectedIndicator) ? `#${routes[updatedIndex].hash}?${selectedIndicator}` : '#' + routes[updatedIndex].hash)
    withScrollSnap && window.document.getElementById(routes[updatedIndex].hash).scrollIntoView()
  }

  const handleScroll = (event) => {
    if (event.type === 'keydown') {
      if (event.key === 'ArrowDown') {
        event.preventDefault() // prevent the key stroke to move the page, introducing errors in the scroll behaviour
        if (pageIndexRef.current !== routes.length - 1) {
          changePage(pageIndexRef.current + 1, true)
        }
      }
      if (event.key === 'ArrowUp') {
        event.preventDefault() // prevent the key stroke to move the page, introducing errors in the scroll behaviour

        if (pageIndexRef.current > 0) {
          changePage(pageIndexRef.current - 1, true)
        }
      }
    } else { // the scroll event fires on each page move, so we need to not fire it when key up or down is pressed
      if (pageIndexRef.current !== routes.length - 1 && event.target.scrollingElement.scrollTop > pageBreakArray[pageIndexRef.current + 1] - windowHeight / 4) {
        changePage(pageIndexRef.current + 1)
      }
      if (pageIndexRef.current > 0 && event.target.scrollingElement.scrollTop < pageBreakArray[pageIndexRef.current] - windowHeight / 2) {
        changePage(pageIndexRef.current - 1)
      }
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', handleScroll)
    window.addEventListener('scroll', handleScroll)
    return () => {
      document.removeEventListener('keydown', handleScroll)
      window.removeEventListener('scroll', handleScroll)
    }
  })

  useEffect(() => {
    dataUtils.parse().then(data => {
      setData(data)
      setYear(data.extents.year[0])
    })
  }, [setYear, setData])

  const copyToClipboard = () => {
    const el = document.createElement('input')
    el.value = window.location
    el.id = 'url'
    el.style.position = 'fixed'
    el.style.left = '-1000px'
    el.setAttribute('readonly', true) // to prevent mobile keyboard from popping up
    document.body.appendChild(el)
    el.select()
    document.execCommand('copy')
    setHashString(window.location.hash)
  }

  useEffect(() => {
    if (hashString && hashString.includes(routes[pageIndex].hash)) {
      setCopyButtonStyle(copyButtonStyle => {
        return { backgroundColor: colors.secondaryDarker }
      })
    } else if (copyButtonStyle.backgroundColor === colors.secondaryDarker) {
      setCopyButtonStyle(copyButtonStyle => {
        return { backgroundColor: 'transparent' }
      })
    }
  }, [pageIndex, hashString]) // eslint-disable-line

  return (
    <main className='base'>
      {!isLoading
        ? (
          <section className="app">
            <div className='controls' style={controlsStyle}>
              <button title="scroll to top" disabled={pageIndex === 0} className='controls__scrollToTop' onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
                <ScrollToTop />
              </button>
              <button title='Click to copy page location' style={copyButtonStyle} className='controls__link' onClick={copyToClipboard}>
                <Link />
              </button>
              <button title="About the developers" className='controls__attribution' onClick={() => !showAttribution && setShowAttribution(true)}>
                <QuestionMark color={showAttribution ? colors.secondaryDarker : colors.secondaryDark}/>
              </button>
              {showAttribution && (
                <div
                  ref={popoutRef}
                  style={isMobileDevice ? {
                    position: 'fixed',
                    top: 'calc(25px + 45px + .5rem)',
                    left: 'calc(25px)',
                    marginRight: '.5rem'
                  } : {}}
                  className="controls__attribution--popout"
                >
                  <p>The website has been designed and developed by <a href="https://www.linkedin.com/in/matthiasbussels/" target="_blank" rel="noopener noreferrer">Matthias Bussels</a></p>
                  <p>Data and text have been provided by <a href="https://www.etui.org/about-etui/staff/sigurt-vitols-0" target="_blank" rel="noopener noreferrer">Sigur Vitols</a> and <a href="https://www.etui.org/about-etui/staff/stan-de-spiegelaere-0" target="_blank" rel="noopener noreferrer">Stan de Spiegelaere</a></p>
                </div>
              )}
            </div>
            <Page index={0} id={routes[0].hash}>{routes[0].content}</Page>
            <IndicatorSelectionPage index={1} id={routes[1].hash} />
            {shouldLoadAll && routes.map((component, index) => {
              if (index > 1) {
                return (
                  <Page
                    index={index}
                    key={`${component.hash}-${index}}`}
                    id={component.hash}
                  >
                    {component.content}
                  </Page>
                )
              }
              return null
            })}
          </section>
        )
        : <Spinner color={colors.secondaryDarker}/>
      }
      <a href="https://www.etui.org" target="_blank" rel="noopener noreferrer">
        <EtuiLogo type='full' />
      </a>
    </main>
  )
}

export default App
