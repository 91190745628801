import React, { useRef } from 'react'
import { useOnClickOutside } from '../hooks'

const Tip = ({ tip, setNext, cancelTutorial }) => {
  const ref = useRef(null)
  useOnClickOutside(ref, () => {})

  const content = {
    init: (
      <>
        <p>Did you know that countries with more <span className="emphasize">democracy at work</span> also have stronger political democracies?</p>
        <br />
        &nbsp;
        <p>
          Select the <span className="emphasize">Political Democracy</span> indicator in the dropdown above to reveal that that countries that score high on the <span className="emphasize">European Participation Index</span> (EPI), tend to have a higher score on the <span className="emphasize">Political Democracy Index</span>.
        </p>
      </>
    ),
    indicator: (
      <>
        <p> A higher score on the <span className="emphasize">Political Democracy Index</span> implies that where employees have a voice in their companies, they also tend to have more say in how their countries are run.
        </p>
        <br />
        &nbsp;
        <p>
          In the underlying chart you can see how, as the <span className="emphasize">EPI</span> increases (x-axis), countries tend to score higher on the <span className="emphasize">political democracy index</span> (y-axis).
        </p>
        <br />
        &nbsp;
        <p>
          After the tutorial you can select other indicators to see how they relate to the broader concept of <span className="emphasize">democracy at work</span>, as represented by the <span className="emphasize">EPI</span> on the x-axis.
        </p>
      </>
    ),
    year: (
      <>
        <p> Now, let&#39;s see how we can <span className="emphasize">use the data</span>.
        </p>
        <br />
        &nbsp;
        <p>
          Change the year by <span className="emphasize">clicking on the year</span> in the bottom-left corner of the graph, to jump to the data corresponding to that year.
        </p>
        <br />
        &nbsp;
        <p style={{ fontStyle: 'italic' }}>The loop function (&#39;play&#39; button) will be unlocked after you&#39;ve completed the tutorial.</p>
      </>
    ),
    graph: (
      <>
        <p>Nice!</p>
        <br />
        &nbsp;
        <p> Now, if you want to know how each country scored on the various underlying indicators, try <span className="emphasize">clicking on the country dot</span> in the graph itself.
        </p>
        <br />
        &nbsp;
        <p style={{ fontStyle: 'italic' }}>
          Simply click outside the appearing popup to close it.
        </p>
      </>
    ),
    description: (
      <>
        <p>Smooth!</p>
        <br />
      &nbsp;
        <p>
          Lastly, click on the <span className="emphasize">arrow to the right</span> of the graph to see a short explanation of each indicator.
        </p>
      </>
    ),
    end: (
      <p>Great! Now you know everything you need to play with the data. For starters, check out the other indicators!</p>
    )
  }

  const tipStyle = tip === 'indicator' ? 'tip__backdrop' : 'tip'
  return (
    <div className={tipStyle} style={(tip === 'init' || tip === 'indicator') ? { alignItems: 'center' } : { top: '30px' }}>
      <div className='tip__content' ref={ref}>
        {content[tip]}
        <div className='tip__controls'>
          {tip === 'init' && <button className='tip__controls--cancel' onClick={cancelTutorial}>Skip tutorial</button>}
          {(tip === 'init' || tip === 'indicator' || tip === 'end') && <button className='tip__controls--confirm' onClick={() => setNext()}>OK</button>}
        </div>
      </div>
    </div>
  )
}

export default Tip
