import React from 'react'

const MediaControls = ({ type, color, size, onClick, className, isDisabled = false }) => {
  const getIcon = () => {
    if (type === 'play') {
      return (
        <svg fill={color || 'white'} width={`${size}px`} height={`${size}px`} version="1.1" viewBox="0 0 100 100" xmlSpace="preserve" xmlns="http://www.w3.org/2000/svg">
          <g transform="scale(.20619)">
            <path d="m413.97 71.026c-45.803-45.801-106.7-71.026-171.47-71.026s-125.67 25.225-171.47 71.026c-45.801 45.803-71.026 106.7-71.026 171.47s25.225 125.67 71.026 171.47c45.803 45.801 106.7 71.026 171.47 71.026s125.67-25.225 171.47-71.026c45.801-45.803 71.026-106.7 71.026-171.47s-25.225-125.67-71.026-171.47zm-171.47 383.97c-117.17 0-212.5-95.327-212.5-212.5s95.327-212.5 212.5-212.5 212.5 95.327 212.5 212.5-95.327 212.5-212.5 212.5z"/>
            <polygon points="181.06 336.58 343.94 242.5 181.06 148.42"/>
          </g>
        </svg>
      )
    }
    if (type === 'pause') {
      return (
        <svg fill={color || 'white'} width={`${size}px`} height={`${size}px`} vversion="1.1" viewBox="0 0 100 100" xmlSpace="preserve" xmlns="http://www.w3.org/2000/svg">
          <path d="m50 0c-27.57 0-50 22.43-50 50s22.43 50 50 50 50-22.43 50-50-22.43-50-50-50zm-5 76.667h-13.333v-53.333h13.333zm23.333 0h-13.333v-53.333h13.333z" strokeWidth="1.6667"/>
        </svg>
      )
    }
  }

  return (
    <div className={`${className} ${isDisabled && 'disabled'}`} onClick={!isDisabled && onClick} >
      {getIcon()}
    </div>
  )
}

export default MediaControls
