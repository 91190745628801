import React from 'react'

const Link = ({ size = 30, color }) => {
  return (
    <svg width={size} height={size} fill={color} version="1.1" viewBox={`0 0 ${size} ${size}`} xmlns="http://www.w3.org/2000/svg">
      <g transform="matrix(.10569 0 0 .10569 -9.4581e-5 -.0012046)">
        <path d="m265.12 22.764-9.877-8.737c-23.003-20.398-58.227-18.283-78.618 4.726l-28.267 31.89c-6.38 7.199-5.717 18.251 1.479 24.637l2.653 2.354c7.221 6.402 18.239 5.741 24.646-1.481l28.265-31.889c6.305-7.107 17.227-7.761 24.338-1.466l9.865 8.752c7.113 6.303 7.783 17.223 1.469 24.334l-61.808 69.726c-5.231 5.911-13.791 7.505-20.816 3.875-7.682-3.967-17.051-2.224-22.787 4.245l-0.482 0.544c-3.881 4.377-5.499 10.188-4.439 15.943 1.061 5.752 4.642 10.604 9.825 13.313 8.197 4.284 17.049 6.358 25.814 6.358 15.532 0 30.795-6.512 41.67-18.775l61.804-69.718c20.368-22.978 18.248-58.247-4.734-78.631z"/>
        <path d="m134 208.58-2.659-2.356c-7.204-6.383-18.259-5.712-24.64 1.489l-28.254 31.886c-6.308 7.105-17.222 7.764-24.327 1.473l-9.879-8.764c-7.115-6.301-7.783-17.212-1.467-24.325l61.806-69.721c5.124-5.787 13.555-7.442 20.504-4.028 7.986 3.924 17.683 2.016 23.595-4.656l0.222-0.25c3.798-4.288 5.396-9.979 4.386-15.614-1.01-5.636-4.484-10.417-9.533-13.119-22.828-12.22-50.769-7.22-67.947 12.165l-61.81 69.707v1e-3c-20.371 22.978-18.252 58.246 4.726 78.622l9.877 8.749c10.583 9.383 23.77 13.992 36.913 13.992 15.394 0 30.724-6.327 41.718-18.724l28.258-31.886c6.393-7.219 5.737-18.235-1.489-24.641z"/>
      </g>
    </svg>
  )
}

export default Link
