import React from 'react'
import { colors } from '../../constants'

const Pulse = ({ id, color = colors.primaryLight, cx, cy }) => {
  return (
    <circle id={id} cx={cx} cy={cy} fill="transparent" r="10" stroke={color || '#fff'} strokeWidth="2">
      <animate attributeName="r" from="5" to="15" dur="1.5s" begin="0s" repeatCount="indefinite"/>
      <animate attributeName="opacity" from="1" to="0" dur="1.5s" begin="0s" repeatCount="indefinite"/>
    </circle>
  )
}

export default Pulse
