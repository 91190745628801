import React, { useRef, useContext } from 'react'
import { store } from '../store'
import { mathUtils } from '../utils'
import { useOnClickOutside } from '../hooks'
import { indicators } from '../constants'

const Dialog = () => {
  const dialogRef = useRef(null)
  const { dialog: { data }, setDialog, selectedIndicator } = useContext(store)

  useOnClickOutside(dialogRef, (event) => {
    if (!dialogRef.current.contains(event.target) || event.key === 'Escape' || event.key === 'Tab') {
      setDialog()
    }
  })

  return (
    <aside className='dialog' ref={dialogRef}>
      <div className="dialog__title">
        <h1>{data.country}</h1>
      </div>
      <hr />
      <section className="dialog__content">
        <label>Trade Union Density</label><p>{mathUtils.getPercentage(data.density, 100)}</p>
        <label>Collective Bargaining Coverage</label><p>{mathUtils.getPercentage(data.cbc, 100)}</p>
        <label>Workplace Representation</label><p>{mathUtils.getPercentage(data.workplace, 100)}</p>
        <label>Board Level Employee Representation</label><p>{data.bler || 0}</p>
        <label>EPI</label><p>{parseFloat(data.epi * 10).toFixed(2)}</p>
        {selectedIndicator && <><label>{indicators[selectedIndicator].label} ({indicators[selectedIndicator].subLabel})</label><p>{data[selectedIndicator]}</p></>}
      </section>
    </aside>
  )
}

export default Dialog
