import React, { useContext, useEffect, useState, useRef } from 'react'
import Select from 'react-select'
import { indicators, routes } from '../constants'
import { store } from '../store'
import { isMobileDevice } from '../utils/isMobile'
import { useWindowDimensions } from '../hooks'
import * as Chart from '../charts'
import Tip from '../components/Tip'

const helpers = [
  'init',
  'indicator',
  'year',
  'graph',
  'description',
  'end',
]

const IndicatorSelectionPage = ({ id, index }) => {
  const { height } = useWindowDimensions()
  const selectRef = useRef(false)
  const ref = useRef()
  const { selectedIndicator, pageIndex, setIndicator, year, dialog, shouldLoadAll, setShouldLoadAll } = useContext(store)
  const [option, setOption] = useState(selectedIndicator ? { value: indicators[selectedIndicator].value, label: indicators[selectedIndicator].label } : null)
  const [showTutorial, setShowTutorial] = useState(pageIndex !== 0 || isMobileDevice ? false : true)
  const [activeTipIndex, setActiveTipIndex] = useState(isMobileDevice || selectedIndicator ? null : 0)
  const [isLoopButtonLocked, setIsLoopButtonLocked] = useState(helpers[activeTipIndex] !== 'year' && showTutorial)

  const setNextHelper = () => {
    const newActiveTipIndex = activeTipIndex + 1
    if (activeTipIndex === helpers.length - 1)  {
      setShowTutorial(false)
      setShouldLoadAll(true)
      selectRef.current = false
    } else {
      setActiveTipIndex(newActiveTipIndex)
      if (newActiveTipIndex === 1 || newActiveTipIndex === 3) setShowTutorial(false)
      if (newActiveTipIndex === 1) {
        selectRef.current = true
        ref.current.state.menuIsOpen = true
      }
    }
  }

  useEffect(() => {
    if (year !== 2009 && helpers[activeTipIndex] === 'year') {
      setActiveTipIndex(activeTipIndex + 1)
      setIsLoopButtonLocked(false)
    }
  }, [year, activeTipIndex])

  useEffect(() => {
    if (helpers[activeTipIndex] === 'graph' && dialog) {
      setActiveTipIndex(activeTipIndex + 1)
      setShowTutorial(false)
    }
    if (helpers[activeTipIndex] === 'description' && !dialog) {
      setShowTutorial(true)
    }
  }, [dialog, activeTipIndex])

  const handleSelection = (e) => {
    setOption(e)
    window.history.pushState({}, '', e ? `#${routes[pageIndex].hash}?${e.value}` : `#${routes[pageIndex].hash}`)
    setIndicator(e?.value || null)
    if (helpers[activeTipIndex] === 'indicator') setShowTutorial(true)
  }

  return (
    <section className='indicatorSelectionPage' id={id} style={{ height }}>
      <div data-aos="fade-up" className="indicatorSelectionPage__selector">
        <Select
          options={Object.values(indicators).map(({ label, value }) => ({ label, value }))}
          onChange={handleSelection}
          classNamePrefix='react-select'
          value={option}
          placeholder='Select an indicator...'
          className='react-select__container'
          inputProps={{ readOnly: isMobileDevice }}
          isSearchable={false}
          blurInputOnSelect
          ref={ref}
          isDisabled={showTutorial}
          isOptionDisabled={(option) => selectRef.current && option.value !== 'democracy'}
        />
      </div>
        <div data-aos="fade-up" className="indicatorSelectionPage__container">
          {showTutorial && <Tip 
            tip={helpers[activeTipIndex]}
            setNext={setNextHelper}
            cancelTutorial={() => {
              setActiveTipIndex(null)
              setShowTutorial(false)
              setShouldLoadAll(true)
              setIsLoopButtonLocked(false)
            }}
            />}
          {selectedIndicator && <Chart.Scatter 
            isDescriptionButtonDisabled={(helpers[activeTipIndex] !== 'description' && showTutorial) || (helpers[activeTipIndex] === 'description' && !!dialog)}
            isLoopButtonDisabled={isLoopButtonLocked || (helpers[activeTipIndex] !== 'year' && showTutorial) || helpers[activeTipIndex] === 'description'}
            isYearButtonDisabled={helpers[activeTipIndex] === 'description' || (helpers[activeTipIndex] !== 'year' && showTutorial)} 
            isGraphDisabled={helpers[activeTipIndex] !== 'graph' && showTutorial} 
            helper={helpers[activeTipIndex]}
            setNextHelper={setNextHelper}
          />}
        </div>
        {shouldLoadAll && <div data-aos data-aos-anchor-placement='top-center' className="indicatorSelectionPage__navigation noselect fade-out">
          {routes[index].guide}
        </div>}
    </section>
  )
}

export default IndicatorSelectionPage
