import React, { useEffect, useRef, useState, useContext } from 'react'
import { useWindowDimensions as getWindowDimensions } from './hooks'
import { isMobileDevice } from './utils/isMobile'
import { routes } from './constants'


export const store = React.createContext({})
const { Provider } = store

const ContextProvider = ({ children }) => {
  const dimensions = getWindowDimensions()
  const intervalRef = useRef()

  const [data, setData] = useState()
  const [year, setYear] = useState()
  const [isLoading, toggleLoading] = useState(true)
  const [isLooping, setIsLooping] = useState(false)
  const [highlightedCountry, setHighlightedCountry] = useState()
  const [dialogContent, setDialogContent] = useState()
  const [pageIndex, setPageIndex] = useState(() => (window.location.hash && routes.filter(route => window.location.hash.includes(route.hash)).length > 0) ? routes.findIndex(route => window.location.hash.includes(route.hash)) : 0)
  const [hashString, setHashString] = useState()
  const [selectedIndicator, setSelectedIndicator] = useState(window.location.hash.split('?')[1] || null)
  const [shouldLoadAll, setShouldLoadAll] = useState(isMobileDevice ? true : !!pageIndex)

  const yearArray = data?.extents?.year;

  useEffect(() => {
    if (!isLoading) {
      const hash = window.location.hash.split('#')[1]
      if (hash) {
        const hasIndicator = hash.split('?').length > 1
        window.document.getElementById(hasIndicator ? hash.split('?')[0] : hash).scrollIntoView({ behavior: 'smooth' })
      }
    }
  }, [isLoading])

  useEffect(() => {
    let timer
    if (data && year && isLoading) {
      timer = setTimeout(() => toggleLoading(false), 1000)
    }
    return () => clearTimeout(timer)
  }, [data, year, isLoading, selectedIndicator])

  useEffect(() => {
    const handleInterval = (command) => {
      if (command === 'start') {
        setYear(year => {
          if (year < yearArray[yearArray.length - 1]) {
            return year + 1
          } else {
            return yearArray[0]
          }
        })
        
        intervalRef.current = setInterval(() => {
          setYear(year => {
            if (year < yearArray[yearArray.length - 1]) {
              return year + 1
            } else {
              return yearArray[0]
            }
          })
        }, 750)
      } else {
        clearInterval(intervalRef.current)
        intervalRef.current = 0
      }
    }

    if (isLooping) handleInterval('start')
    if (!isLooping && intervalRef.current) handleInterval('stop')

    return () => handleInterval('stop')
  }, [isLooping, yearArray])
    

  const setDialog = (data = null) => {
    if (isLooping) setIsLooping(false)
    setDialogContent(data ? { page: routes[pageIndex].hash, data } : null)
  }

  const setIndicator = (indicator) => {
    setSelectedIndicator(indicator)
  }

  return (
    <Provider value={{
      hashString,
      data,
      highlightedCountry,
      isLoading,
      isLooping,
      pageIndex,
      selectedIndicator,
      setData,
      setHashString,
      setHighlightedCountry,
      setIndicator,
      setIsLooping,
      setPageIndex,
      setDialog,
      setYear,
      setShouldLoadAll,
      shouldLoadAll,
      dialog: dialogContent,
      window: dimensions,
      year
    }}>
      {children}
    </Provider>
  )
}

export const useWindowDimensions = () => useContext(store).window
export default ContextProvider
