import { useEffect, useRef } from 'react'

export const useOnClickOutside = (ref, callback) => {
  const cbRef = useRef(callback)

  useEffect(() => {
    cbRef.current = callback
  }, [callback])

  useEffect(
    () => {
      const listener = event => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) {
          return
        }
        cbRef.current(event)
      }

      document.addEventListener('mousedown', listener)
      document.addEventListener('touchstart', listener)
      document.addEventListener('keydown', listener)

      return () => {
        document.removeEventListener('mousedown', listener)
        document.removeEventListener('touchstart', listener)
        document.removeEventListener('keydown', listener)
      }
    },
    [ref]
  )
}

export default useOnClickOutside
