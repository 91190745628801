import React from 'react'

const ArrowButton = ({ direction, color, size, onClick, className, isDisabled }) => {
  const getIcon = () => {
    if (direction === 'up') {
      return (
        <svg width={size || '13.229mm'} fill={color || '#000'} height={size / 2 || '6.863mm'} version="1.1" viewBox="0 0 13.229 6.863" xmlns="http://www.w3.org/2000/svg">
          <g transform="translate(-5.6593 -28.742)">
            <path d="m15.644 32.158 3.245 3.4477-13.229-0.0038 3.2942-3.4131c1.8118-1.8772 3.3281-3.4279 3.3696-3.4459 0.04151-0.01802 1.5357 1.5187 3.3204 3.415z" opacity=".98714"/>
          </g>
        </svg>
      )
    }
    if (direction === 'down') {
      return (
        <svg width={size || '13.229mm'} fill={color || '#000'} height={size / 2 || '6.863mm'} version="1.1" viewBox="0 0 13.229 6.863" xmlns="http://www.w3.org/2000/svg">
          <g transform="translate(-5.6593 -28.742)">
            <path d="m8.9043 32.19-3.245-3.4477 13.229 0.0038-3.2942 3.4131c-1.8118 1.8772-3.3281 3.4279-3.3696 3.4459-0.04151 0.01802-1.5357-1.5187-3.3204-3.415z" opacity=".98714"/>
          </g>
        </svg>
      )
    }
    if (direction === 'right') {
      return (
        <svg width={size / 2 || '13.229mm'} fill={color || '#000'} height={size || '6.863mm'} version="1.1" viewBox="0 0 6.863 13.229" xmlns="http://www.w3.org/2000/svg">
          <g transform="rotate(90 20.633 14.973)">
            <path d="m15.644 32.158 3.245 3.4477-13.229-0.0038 3.2942-3.4131c1.8118-1.8772 3.3281-3.4279 3.3696-3.4459 0.04151-0.01802 1.5357 1.5187 3.3204 3.415z" opacity=".98714"/>
          </g>
        </svg>)
    }
    return (
      <svg width={size / 2 || '13.229mm'} fill={color || '#000'} height={size || '6.863mm'} version="1.1" viewBox="0 0 6.863 13.229" xmlns="http://www.w3.org/2000/svg">
        <g transform="rotate(-90 -4.9269 23.816)">
          <path d="m15.644 32.158 3.245 3.4477-13.229-0.0038 3.2942-3.4131c1.8118-1.8772 3.3281-3.4279 3.3696-3.4459 0.04151-0.01802 1.5357 1.5187 3.3204 3.415z" opacity=".98714"/>
        </g>
      </svg>

    )
  }

  return (
    <button disabled={isDisabled} onClick={onClick} className={className}>
      {getIcon()}
    </button>
  )
}

export default ArrowButton
