import React from 'react'
import { colors } from '../constants'
import { useOnClickOutside } from '../hooks'
import { store } from '../store'
import MediaControls from '../assets/icons/MediaControls'
import setClassname from 'classnames'

const ChartControl = ({ position, isButtonDisabled, isYearButtonDisabled }) => {
  const controlRef = React.useRef()
  const [isSelectorOpen, setIsSelectorOpen] = React.useState(false)
  const { data: { extents }, year, setYear, isLooping, setIsLooping } = React.useContext(store)

  useOnClickOutside(controlRef, () => setIsSelectorOpen(false))

  const mediaControlStyle = setClassname(
    'chart__control--action',
    { looping: isLooping },
    { disabled: isButtonDisabled }
  )

  const yearButtonStyle = setClassname(
    'chart__control--year',
    'closed',
    { looping: isLooping }
  )

  return (
    <div ref={controlRef} className="chart__control" style={position}>
      {isSelectorOpen ? (
        <div className='chart__control--year open'>
          {extents.year.map(val => (
            <button
              className={val === year ? 'selected' : undefined}
              key={val}
              onClick={() => {
                if (isLooping) setIsLooping(false)
                setYear(val)
                setIsSelectorOpen(false)
              }
              }>
              {val}
            </button>
          ))}
        </div>
      ) : (
        <button
          className={yearButtonStyle}
          onClick={() => setIsSelectorOpen(!isSelectorOpen)}
          disabled={isYearButtonDisabled}
        >
          {year}
        </button>
      )}
      <MediaControls className={mediaControlStyle} onClick={() => setIsLooping(!isLooping)} size={30} color={colors.secondaryDarker} type={isLooping ? 'pause' : 'play'}/>
    </div>
  )
}

export default ChartControl
