export const colors = {
  primary: '#000000',
  primaryLight: '#555555',
  secondary: '#BCCFD6',
  secondaryDark: '#B8D0D7',
  secondaryDarker: '#73A3B1',
  secondaryLight: '#F3F3F3',
  blue: '#00467F',
  blueDark: '#012E52',
  blueLight: '#8FC3E9',
  blueLighter: '#C2E2F7',
  antiPrimary: '#FFFFFF'
}
