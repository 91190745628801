import React from 'react'

export const indicators = {
  employment: {
    value: 'employment',
    label: 'Employment rate',
    subLabel: '% of active population in employment',
    text:
    <span>
      There’s no trade off between quality and quantity. Those countries that give employees a strong voice at work are also those in which many people are in employment. Democracy at work goes hand-in-hand with high <span className='emphasize'>employment rates</span>. While some say democracy at work might lead to lower employment as the price of employment (read: wages) is going up, the opposite seems to be the case. Why? One explanation can be that for employees, guaranteeing and extending employment is a top-priority and strengthening the voice of workers in companies contributes to those companies taking employment friendly policies.
    </span>,
    source:
    'Eurostat, employment rate, age group 20-64'
  },
  inequality: {
    value: 'inequality',
    label: 'Inequality',
    subLabel: 'Gini coefficient',
    text:
  <span>
      According to the World Inequality Report (2018), ‘organized employee voice on all levels is a strong antidote for <span className='emphasize'>inequality</span> as it reduces management and shareholder greed and ensures higher (and equal) wages’. And indeed, those countries with strong democracy at work are also those with much lower gini-coefficients, meaning they are more equal. Changing the way decisions are made also affects those decisions. And in this case, giving a voice to employees in companies and in collective bargaining affects decisions on wages at the bottom, but also at the top (CEO-pay) and hence limits the income inequality.
  </span>,
    source:
    'Eurostat, Gini coefficient of equivalised disposable income [ilc_di12]'
  },
  democracy: {
    value: 'democracy',
    label: 'Political democracy',
    subLabel: 'Political Democracy Index',
    text:
  <span>
      Democracy is more than only going to the ballot every so often. A strong <span className='emphasize'>political democracy</span> requires critical free thinking citizens who believe in the value of democracy, listening, collective decision making and free speech. And the best way to teach those values is through ‘learning by doing’ on the workplace. Citizens who, at work, have a say in company decisions, can vote on their representatives, can make an opinion and voice it will have be more likely to believe and support political democracy. And indeed, those countries with high levels of democracy at work are also those which score high on the Economist Democracy Index. Political democracy and democracy at work go hand in hand.
  </span>,
    source:
     'Economist Intelligence Unit democracy index'
  },
  rd: {
    value: 'rd',
    label: 'Investments in R&D',
    subLabel: 'Expenditure as a % of GDP',
    text:
  <span>
      Innovation is key for long-term economic prosperity. And democracy at work might play a role in this. As can be seen in the graph, those countries that give a voice to workers in companies are also those in which quite a large share of their GDP goes to <span className='emphasize'>investments in research and development</span>. Employees generally prefer ‘high-road’ strategies to competitiveness by being better than the competitors, rather than cheaper and thus push companies to invest more in innovation.
  </span>,
    source:
    'Eurostat, Gross domestic expenditure on R&D'
  },
  labourShare: {
    value: 'labourShare',
    label: 'Labour share',
    subLabel: 'GDP',
    text:
  <span>
      The post-war pact between capital and labour was clear: labour will cooperate in making the companies more productive, and the gains will be equally shared between both. However, in the last decennia, the <span className='emphasize'>wage share</span> in the total economy has been declining almost everywhere. The productivity gains, in other words, have largely gone to capital rather than labour. Yet where employees have a strong voice in companies, where there is a high level of democracy at work, a larger proportion of created wealth goes to labour.
  </span>,
    source:
    'ILO, labour income share as percent of GDP'
  },
  poverty: {
    value: 'poverty',
    label: 'In-work poverty',
    subLabel: '% population in work at risk of poverty',
    text:
  <span>
Work should pay. If a person is in a paid employment, he/she shouldn’t have an income that is below the <span className='emphasize'>poverty</span> line. However, this is still the case for a considerable number of workers, also in Europe. But this is much less the case in those countries where employees have a voice in company decisions, where there is democracy at work. In those countries, the proportion ‘working poor’ is much lower than in countries where employees have little say in companies.
  </span>,
    source:
    'Eurostat: In-work, at-risk-of-poverty-rate, 18+ [ilc_iw01]'
  }
}
