
import React, { useContext } from 'react'
import UISlider from '@material-ui/core/Slider'
import { withStyles } from '@material-ui/core'
import { store } from '../store'
import { colors } from '../constants'

const StyledSlider = withStyles({
  root: {
    color: colors.secondaryDarker,
    padding: '0',
    width: '70%',
    display: 'flex',
    alignItems: 'center',
    marginTop: '20px'
  },
  markLabel: {
    fontSize: '0.79rem',
    top: '1.5rem',
    color: colors.secondaryDarker
  },
  markLabelActive: {
    borderBottom: `1px solid ${colors.secondaryDark}`,
    top: '2rem',
    fontWeight: 'bold'
  },
  mark: {
    height: '1rem !important'
  },
  thumb: {
    height: '1.5rem !important',
    width: '1.5rem !important',
    marginTop: '0',
    color: colors.secondaryDarker
  }
})(UISlider)

const Slider = ({ isSliderDisabled }) => {
  const { data: { extents: { year: years } }, year, handleSlider } = useContext(store)
  return (
    <div className="slider" id="slider">
      <StyledSlider
        disabled={isSliderDisabled}
        orientation='horizontal'
        min={years[0]}
        max={years[years.length - 1]}
        marks={years.map(year => {
          return {
            value: year,
            label: year.toString()
          }
        })}
        step={1}
        value={year}
        defaultValue={years[0]}
        onChange={handleSlider}
        aria-labelledby="discrete-slider"
        valueLabelDisplay="off"
        track={false}
      />
    </div>
  )
}

export default Slider
