
export default [
  {
    year: 2009,
    epi: 0.5328905
  },
  {
    year: 2010,
    epi: 0.5314272
  },
  {
    year: 2011,
    epi: 0.5307692
  },
  {
    year: 2012,
    epi: 0.5243459
  },
  {
    year: 2013,
    epi: 0.5116182
  },
  {
    year: 2014,
    epi: 0.5201526
  },
  {
    year: 2015,
    epi: 0.5035232
  },
  {
    year: 2016,
    epi: 0.5017792
  },
  {
    year: 2017,
    epi: 0.5037471
  },
  {
    year: 2018,
    epi: 0.5029764
  },
  {
    year: 2019,
    epi: 0.4881251
  }
]
