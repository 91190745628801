import React, { useEffect, useRef } from 'react'

import * as d3 from 'd3'
import { colors } from '../../constants'
const Chart = ({ data, scales, width, height }) => {
  const chartRef = useRef(null)

  useEffect(() => {
    if (data && scales) {
      const viz = d3.select(chartRef.current)
        .attr('height', height)
        .attr('width', width)

      const createLine = d3.line()
        .x(d => scales.xScale(d.year))
        .y(d => scales.yScale(d.epi))

      viz.select('#changepath').remove()
      viz.append('path')
        .datum(data)
        .attr('id', 'changepath')
        .attr('d', createLine)
        .attr('fill', 'none')
        .attr('stroke', colors.primaryLight)
        .attr('shape-rendering', 'auto')

      viz.selectAll('circle')
        .data(data, d => d && d.year)
        .join(
          enter => enter.append('circle')
            .attr('id', d => `mean_${d.year}`)
            .attr('r', 5)
            .attr('cx', d => scales.xScale(d.year))
            .attr('cy', d => scales.yScale(d.epi))
            .attr('fill', colors.primaryLight)
        ).transition().duration(750)
        .attr('cx', d => scales.xScale(d.year))
        .attr('cy', d => scales.yScale(d.epi))
    }
  }, [data, scales, width, height])

  return (
    <g ref={chartRef} />
  )
}

export default Chart
