import React, { useRef, useLayoutEffect, useEffect, useState, useContext, useCallback } from 'react'
import * as d3 from 'd3'

import Chart from './LineChart'
import { margins, colors } from '../../constants'
import { store } from '../../store'
import { chartUtils } from '../../utils'
import { Pulse } from '../../assets/icons'
import { useOnClickOutside } from '../../hooks'

const Canvas = ({ width, height }) => {
  const popupRef = useRef()
  const chartRef = useRef()
  const [scales, setScales] = useState()
  const [dimensions, setDimensions] = useState({ width, height: height * 0.5 })
  const { year, data: { data: { europeMeanData }, extents } } = useContext(store)
  const [location2014, setLocation2014] = useState()
  const [popup, showPopup] = useState()

  useOnClickOutside(popupRef, (event) => {
    if (!popupRef.current.contains(event.target) || event.key === 'Escape' || event.key === 'Tab') {
      handlePopup()
    }
  })
  useLayoutEffect(() => {
    if (chartRef.current) {
      setDimensions(dimensions => {
        return {
          width,
          height: dimensions.height
        }
      })
    }
  }, [width])

  useEffect(() => {
    const canvas = d3.select('#line__canvas')

    if (dimensions.width && dimensions.height) {
      if (!scales) {
        const xScale = d3.scaleBand().domain(extents.year).range([margins.left, dimensions.width - margins.right]).padding(1)

        chartUtils.appendAxis({
          selection: canvas,
          type: 'xAxis',
          dimensions,
          scale: xScale
        })

        const yScale = d3.scaleLinear().domain([0.4, 0.6]).range([dimensions.height - margins.bottom, margins.top])

        canvas.append('g')
          .attr('id', 'y-axis')
          .attr('transform', `translate(${margins.left}, 0)`)
          .call(d3.axisLeft(yScale).ticks(5).tickFormat(x => x.toString().substr(1)))
          .call(g => g.selectAll('.tick')
            .filter(function (d) { return d === 0 })
            .remove())
          .call(g => g.select('.domain').remove()) // removing the path'd axis
          .append('line') // adding a straight line as axis, without the corners
          .attr('class', 'y-axis-line')
          .attr('x1', 0)
          .attr('x2', 0)
          .attr('y1', margins.top)
          .attr('y2', dimensions.height - margins.bottom)
          .attr('stroke', colors.primary)

        setScales({
          xScale,
          yScale
        })
      } else {
        const xScale = d3.scaleBand().domain(extents.year).range([margins.left, dimensions.width - margins.right]).padding(1)
        chartUtils.updateAxis({
          selection: canvas,
          dimensions,
          scale: xScale,
          type: 'xAxis'
        })

        setScales(scales => {
          return {
            ...scales,
            xScale
          }
        })
      }
    }
  }, [year, dimensions]) // eslint-disable-line

  useEffect(() => {
    if (scales) {
      const data2014 = europeMeanData.find(datum => datum.year === 2014)
      setLocation2014({ x: scales.xScale(data2014.year), y: scales.yScale(data2014.epi) })
    }
  }, [scales, europeMeanData])

  const handlePopup = useCallback(() => {
    if (!popup) {
      showPopup(true)
    }
    if (popup) {
      showPopup(false)
    }
  }, [popup])

  useEffect(() => {
    if (location2014) {
      d3.select('#pulse_2014')
        .on('mouseover', () => { document.getElementById('pulse_2014').style.cursor = 'pointer' })
        .on('click', handlePopup)
    }
  }, [location2014, handlePopup])

  const renderPopup = () => {
    return (
      <div className='popup' style={{ width: `${dimensions.height}px` }} ref={popupRef}>
        <p>
        In 2014, the French governement introduced quite a far reaching system of having employee representatives in company boards. This is the reason why the EPI index increased a bit in this year. Unfortunately, it didn’t lead to a sustainable increase in the level of democracy at work.
        </p>
      </div>
    )
  }

  const chartStyle = dimensions.height ? { height: `${dimensions.height}px`, width: '100%' } : {}
  return (
    <div className='line'>
      <div
        ref={chartRef}
        className="line__canvas"
        style={chartStyle}
      >
        {/* basic responsiveness pattern for svg's => you can force dimensions consistency by setting it to a specific px value (from  or local state) or allow it to vary by setting it to 100% */}
        <svg
          id="line__canvas"
          viewBox={`0 0 ${dimensions.width} ${dimensions.height}`}
          height='100%'
          width='100%'
        >
          <Chart
            data={europeMeanData}
            width={dimensions.width}
            height={dimensions.height}
            scales={scales}
          />
          {location2014 &&
            <Pulse id='pulse_2014' cx={location2014.x} cy={location2014.y} />
          }
        </svg>
        <h2 className='xAxis noselect' style={{ bottom: `${margins.bottom + 10}px `, left: `${margins.left}px` }}>European Mean</h2>
      </div>
      {popup && renderPopup()}
    </div>
  )
}

export default Canvas
