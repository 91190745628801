import React, { useContext, useLayoutEffect, useRef, useState } from 'react'

import LollipopChart from './Lollipop/Canvas'
import ScatterChart from './Scatter/Canvas'
import LineChart from './Line/Canvas'

import { Dialog } from '../components'
import { useWindowDimensions } from '../hooks'
import { store } from '../store'
import { routes } from '../constants'

const Scatter = (props) => {
  const { dialog, pageIndex} = useContext(store)
  const { height, width } = useWindowDimensions()
  const wrapperRef = useRef()
  const [dimensions, setDimensions] = useState()

  useLayoutEffect(() => {
    if (wrapperRef.current) {
      setDimensions(dimensions => {
        return {
          width: wrapperRef.current.offsetWidth, // max-width: 800 prevents this value from passing 800
          height: dimensions?.height || wrapperRef.current.offsetHeight // keep it constant
        }
      })
    }
  }, [width])

  return (
    <div className='chart' ref={wrapperRef}>
      {dialog?.page === routes[pageIndex].hash && <Dialog />}
      <ScatterChart 
        width={dimensions?.width || width} 
        height={dimensions?.height || height} 
        isGraphDisabled={props.isGraphDisabled}
        isDescriptionButtonDisabled={props.isDescriptionButtonDisabled}
        helper={props.helper}
        setNextHelper={props.setNextHelper}
        isLoopButtonDisabled={props.isLoopButtonDisabled}
        isYearButtonDisabled={props.isYearButtonDisabled}
       /> 
      <span className="chart--yAxis noselect">European Participation Index</span>
    </div>
  )
}

const Lollipop = () => {
  const { dialog, pageIndex } = useContext(store)
  const { height, width } = useWindowDimensions()
  const wrapperRef = useRef()
  const [dimensions, setDimensions] = useState()

  useLayoutEffect(() => {
    if (wrapperRef.current) {
      setDimensions(dimensions => {
        return {
          width: wrapperRef.current.offsetWidth, // max-width: 800 prevents this value from passing 800
          height: dimensions?.height || wrapperRef.current.offsetHeight // keep it constant
        }
      })
    }
  }, [width])

  return (
    <div className='chart' ref={wrapperRef}>
      {dialog?.page === routes[pageIndex].hash && <Dialog />}
      <LollipopChart width={dimensions?.width || width} height={dimensions?.height || height} /> 
      <span className="chart--yAxis noselect">European Participation Index</span>
    </div>
  )
}

const Line = () => {
  const { height, width } = useWindowDimensions()
  const wrapperRef = useRef()
  const [dimensions, setDimensions] = useState()

  useLayoutEffect(() => {
    if (wrapperRef.current) {
      setDimensions(dimensions => {
        return {
          width: wrapperRef.current.offsetWidth,
          height: dimensions?.height || wrapperRef.current.offsetHeight // keep it constant
        }
      })
    }
  }, [width])

  return (
    <div className='chart__line' ref={wrapperRef}>
      <span className="chart__line--description">
      The short answer is: <span className="emphasize">not so good!</span> Over the years, democracy at work has unfortunately not been on the rise. Trade union membership is declining, collective bargaining is weakening and works councils are present in fewer organizations. The average country score of the <span className="emphasize">EPI</span> (weighted by national employment), therefore, is steadily declining.
      </span>
      <LineChart width={dimensions?.width || width} height={dimensions?.height || height} />
      <span className="chart__line--yAxis noselect">European Participation Index</span>
    </div>
  )
}

export { Line, Scatter, Lollipop }
